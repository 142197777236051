<template>
  <v-row>
    <v-col cols="12">
      <div class="d-flex">
        <v-text-field
          class="ml-auto"
          append-icon="$search"
          placeholder="Search"
          v-model="search"
          outlined
          style="width: 256px; max-width: 100%; flex: unset"
        ></v-text-field>
      </div>
    </v-col>

    <v-col v-if="isEmpty && !loading">
      <v-card class="pa-4">
        <v-img
          src="@/assets/images/data_empty.png"
          width="200"
          class="text-center mx-auto"
        />
        <div class="title text-center mt-2">Data hasil program kosong</div>
      </v-card>
    </v-col>

    <template v-else>
      <v-col id="dt-program">
        <v-data-table
          class="transparent"
          :loading="loading"
          :headers="headers"
          :items="dataList.list"
          :items-per-page="10"
          :page.sync="page"
          :item-key="'id'"
          :mobile-breakpoint="0"
          :server-items-length="dataList.total"
          loading-text="Loading... Please wait"
          :footer-props="{
            'items-per-page-text': 'Row per page : ',
          }"
        >
          <template slot="item" slot-scope="{ item }">
            <tr class="flat-b-1 rounded-lg">
              <td class="white rounded-l-lg">
                <router-link
                  class="color--text font-weight-bold text-capitalize"
                  :to="`/results/${item.id}`"
                >
                  {{ item.license }}
                </router-link>
              </td>
              <td class="white">
                {{ item.program.title }}
              </td>
              <td class="white">{{ item.member.email }}</td>
              <td class="white text-capitalize">{{ item.member.fullname }}</td>
              <td class="white rounded-r-lg">{{ item.start_test_at }}</td>
              <td class="white rounded-r-lg">
                <v-chip v-if="item.is_complete" label outlined color="success"
                  >Selesai</v-chip
                >
                <v-chip v-else label outlined color="warning">Belum Selesai</v-chip>
              </td>
              <!-- <td class="white rounded-r-lg" style="width: 5%">
                <div class="d-flex flex-row">ini aksi</div>
              </td> -->
            </tr>
          </template>
        </v-data-table>
      </v-col>
    </template>

    <v-snackbar
      top
      v-model="snackbar.state"
      color="primary"
      outlined
      :timeout="timeout"
    >
      <div v-html="snackbar.text"></div>
      <template v-slot:action="{ attrs }">
        <v-btn
          small
          icon
          color="error"
          v-bind="attrs"
          @click="snackbar.state = false"
        >
          <v-icon>$close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    dataList: {},
    page: 1,
    search: "",
    snackbar: {
      state: false,
      text: "",
    },
    timeout: null,
  }),
  computed: {
    isEmpty() {
      return !this.dataList.total;
    },
    headers() {
      return [
        { text: "Kode Lisensi", class: "text-no-wrap", sortable: false },
        { text: "Nama Program", class: "text-no-wrap", sortable: false },
        { text: "Email", class: "text-no-wrap", sortable: false },
        { text: "Nama", class: "text-no-wrap", sortable: false },
        { text: "Tanggal Tes", class: "text-no-wrap", sortable: false },
        { text: "Status", class: "text-no-wrap", sortable: false },
        // { text: "Aksi", class: "text-no-wrap", sortable: false },
      ];
    },
  },
  watch: {
    page() {
      this.fetchList();
    },
    search() {
      clearTimeout(this.timeout);
      this.timeout = null;
      this.timeout = setTimeout(() => {
        this.page = 1;
        this.fetchList();
      }, 3e2);
    },
  },
  mounted() {
    this.fetchList();
  },
  methods: {
    async fetchList() {
      this.loading = true;
      this.axios
        .get(`users/v1/company/member-redeem/list`, {
          params: {
            q: this.search,
            page: this.page,
            dir: "desc",
            sort: "created_at",
          },
        })
        .then((res) => res.data)
        .then((res) => {
          this.dataList = res.data;
          console.log(res.data);
        })
        .catch((e) => {
          throw e;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    catchError(error) {
      const msg = error.response ? error.response.data.message : error.message;
      this.snackbar.state = true;
      this.snackbar.text = msg;
    },
  },
};
</script>

<style scoped>
#dt-program .v-data-table > .v-data-table__wrapper > table {
  border-spacing: 0 10px !important;
}
#dt-program .v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td {
  height: 80px !important;
}
.theme--light
  #dt-program
  .v-data-table
  > .v-data-table__wrapper
  > table
  > thead
  > tr:last-child
  > th {
  border: 0px solid !important;
}
.theme--light #dt-program .v-data-table .v-data-footer {
  border: 0px solid !important;
}
#dt-program .v-data-footer__select {
  justify-content: left !important;
  position: absolute;
  left: 0;
}
.v-application--is-ltr #dt-program .v-data-footer__select .v-select {
  margin: 13px 0 13px 0px;
}
</style>